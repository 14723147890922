import { useRef, useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperObject } from "swiper";

import { SlidesContext } from "./Slides/useSlidesContext";
import Slide from "./Slides/Slide";

type Props = {
  slides: Slide[];
};

//const autoplayInterval = 6000;
const autoplayInterval = null;

export default function Slides({ slides }: Props) {
  const [slide, setSlide] = useState(slides[0]);

  const swiperRef = useRef<SwiperObject>();

  const swipeTo = (slide: Slide) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(slides.indexOf(slide));
    }
  };

  const navigate = (offset: number) => {
    let index = (slides.indexOf(slide) + offset) % slides.length;
    if (index < 0) {
      index += slides.length;
    }
    swipeTo(slides[index]);
  };

  useEffect(() => {
    if (autoplayInterval) {
      const interval = setInterval(() => navigate(1), autoplayInterval);
      return () => clearInterval(interval);
    }
  }, [navigate]);

  return (
    <SlidesContext.Provider
      value={{
        slides: slides,
        swipeTo: swipeTo,
        navigate: navigate
      }}>
      <div className="slides">
        <Swiper
          navigation
          slidesPerView={1}
          onSwiper={(s) => (swiperRef.current = s)}
          onSlideChange={(s) => setSlide(slides[s.activeIndex])}>
          {slides.map((s) => (
            <SwiperSlide key={s.id}>
              <Slide slide={s} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </SlidesContext.Provider>
  );
}
