import { createContext, useContext } from "react";

type Context = {
  slides: Slide[];
  swipeTo: (slide: Slide) => void;
  navigate: (offset: number) => void;
};

export const SlidesContext = createContext<Context>(null);

export default function useSlidesContext() {
  return useContext(SlidesContext);
}
