type Props = {
  slide: Slide;
};

export default function SlideLink({
  slide,
  children
}: React.PropsWithChildren<Props>) {
  if (slide.url) {
    return <a href={slide.url}>{children}</a>;
  } else {
    return children;
  }
}
