import Picture from "../Picture";
import Navigation from "./Navigation";
import SlideLink from "./SlideLink";

type Props = {
  slide: Slide;
};

export default function Slide({ slide }: Props) {
  const style = {
    backgroundColor: slide.background_color,
    color: slide.foreground_color
  };

  return (
    <div className="slide" style={style}>
      <div className="image-wrapper">
        <div className="image">
          {slide.image && (
            <SlideLink slide={slide}>
              <Picture
                image={slide.image}
                sizes="(max-width: 920px) 100vw, 67vw"
              />
            </SlideLink>
          )}
        </div>
      </div>
      <div className="text">
        <div className="content">
          <h2>
            <SlideLink slide={slide}>{slide.name}</SlideLink>
          </h2>
          {slide.body && (
            <div dangerouslySetInnerHTML={{ __html: slide.body }}></div>
          )}
        </div>
        <Navigation slide={slide} />
      </div>
    </div>
  );
}
