import useSlidesContext from "./useSlidesContext";

type Props = {
  slide: Slide;
};

export default function Navigation({ slide }: Props) {
  const { slides, swipeTo, navigate } = useSlidesContext();

  const handleGoto = (s: Slide) => () => {
    swipeTo(s);
  };

  const handleNavigate = (offset: number) => () => {
    navigate(offset);
  };

  return (
    <div className="navigation">
      <div className="pagination">
        {slides.map((s) => (
          <button
            key={s.id}
            onClick={handleGoto(s)}
            className={s == slide ? "current" : ""}>
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill={slide.foreground_color} />
            </svg>
          </button>
        ))}
      </div>
      <div className="buttons">
        <button onClick={handleNavigate(-1)}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.367 23.892a.614.614 0 0 0 .866 0l.35-.35a.61.61 0 0 0-.011-.865l-4.333-4.176h9.633a.61.61 0 0 0 .608-.608v-.506a.61.61 0 0 0-.608-.608H14.24l4.328-4.176a.605.605 0 0 0 .01-.866l-.35-.35a.608.608 0 0 0-.86 0L11.54 17.21a.608.608 0 0 0 0 .86l5.827 5.822z"
              fill={slide.foreground_color}
            />
          </svg>
        </button>
        <button onClick={handleNavigate(1)}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.633 11.388a.614.614 0 0 0-.866 0l-.35.35a.61.61 0 0 0 .011.865l4.333 4.176h-9.633a.61.61 0 0 0-.608.608v.506a.61.61 0 0 0 .607.608h9.64l-4.334 4.176a.605.605 0 0 0-.01.866l.35.35a.608.608 0 0 0 .86 0l5.827-5.823a.608.608 0 0 0 0-.86l-5.827-5.822z"
              fill={slide.foreground_color}
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
